<template>
  <div>
    <div class="title">{{$t('titleI18n.BaseInfo')}}</div>
    <el-form :rules="rules" ref="formInfo" :model="formInfo" label-width="210px">
      <el-form-item :label="$t('formTitleI18n.BusinessType')" prop="businessType" required>
        <el-select
          v-model="formInfo.businessType"
          :placeholder="$t('formPlaceHolder.PhBusinessType')"
          style="width: 100%"
        >
          <el-option
            v-for="item in businessType"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.CoreUserName')" prop="payer" required>
        <el-select
          v-model="formInfo.payer"
          :placeholder="$t('formPlaceHolder.PhCoreUserName')"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in brandOwnerList"
            :key="index"
            :label="item.boUser.username"
            :value="item.boUser.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderAmount')" prop="amount" required>
        <el-input
          v-model="formFormat.amount"
          @change="formatAmount"
          :placeholder="$t('formPlaceHolder.PhOrderAmount')"
          style="width: calc(100% - 130px)"
          maxlength="20"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.OrderTime')"
        prop="dueDate"
        required
        style="width: 100%;"
      >
        <el-date-picker
          style="width: 210px"
          v-model="formInfo.dueDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @focus="pickerDateFocus"
          :pickerOptions="pickerOptions"
          :placeholder="$t('formPlaceHolder.PhOrderTime')"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ProductType')"
        prop="desctiption"
        required
        style="width: 100%;"
      >
        <el-input
          type="textarea"
          v-model="formInfo.desctiption"
          :placeholder="$t('formPlaceHolder.PhProductType')"
          :autosize="{ minRows: 4}"
          style="width: 90%;"
          maxlength="500"
        ></el-input>
      </el-form-item>

      <div class="title">{{$t('titleI18n.InvoiceInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.InvoiceUpload')"
        prop="invoice"
        required
        style="width: 100%;"
      >
        <div class="form-upload-ph">{{ $t('formPlaceHolder.PhInvoiceUpload') }}</div>
        <el-upload
            class="upload-demo"
            action="/api/v1/upload/file"
            accept=".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF"
            :on-remove="handleRemove2"
            :before-remove="beforeRemove"
            :on-success="onSuccess2"
            multiple
            :headers="headers"
            :limit="10"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">
            {{
              $t('fileUpLoad')
            }}
          </el-button>
        </el-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.PhInvoiceUploadTips') }}</div>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceNum')" prop="invoiceCode" required>
        <el-input
          v-model="formInfo.invoiceCode"
          :placeholder="$t('formPlaceHolder.PhInvoiceNum')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceAmount')" prop="invoiceValue" required>
        <el-input
          v-model="formFormat.invoiceValue"
          :placeholder="$t('formPlaceHolder.PhInvoiceAmount')"
          @change="formatAmount2"
          style="width: calc(100% - 130px)"
          maxlength="20"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.ContractInfo')}}</div>
      <el-form-item :label="$t('formTitleI18n.ContractNum')" prop="contractNumber">
        <el-input
          v-model="formInfo.contractNumber"
          :placeholder="$t('formPlaceHolder.PhContractNum')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractName')" prop="contractName">
        <el-input
          v-model="formInfo.contractName"
          :placeholder="$t('formPlaceHolder.PhContractName')"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractAmount')" prop="contractAmount">
        <el-input
          v-model="formFormat.contractAmount"
          :placeholder="$t('formPlaceHolder.PhContractAmount')"
          @change="formatAmount3"
          style="width: calc(100% - 130px)"
          maxlength="20"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractTime')" prop="contractValid">
        <el-date-picker
          style="width: 100%"
          v-model="formInfo.contractValid"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :placeholder="$t('formPlaceHolder.PhContractTime')"
        ></el-date-picker>
      </el-form-item>

      <div class="title">{{$t('titleI18n.RepayType')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.RepayType')"
        prop="contractPaymentMode"
        required
        :label-width="language === 'zh-cn' ? '210px' : '300px'"
      >
        <el-select
          v-model="formInfo.contractPaymentMode"
          :placeholder="$t('formPlaceHolder.PhRepayType')"
          style="width: 100%"
        >
          <el-option
            v-for="item in repayType"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <div class="title">{{$t('titleI18n.TicketInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.TicketUpload')"
        prop="contractDetail"
        required
        style="width: 100%;"
      >
        <div class="form-upload-ph">{{ $t('formPlaceHolder.PhTicketUpload') }}</div>
        <el-upload
            class="upload-demo"
            action="/api/v1/upload/file"
            accept=".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF"
            :on-remove="handleRemove1"
            :before-remove="beforeRemove"
            :on-success="onSuccess1"
            multiple
            :headers="headers"
            :limit="10"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">
            {{
              $t('fileUpLoad')
            }}
          </el-button>
        </el-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.PhTicketUploadTips') }}</div>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="submitClick()" :disabled="isSubmit">{{$t('submit')}}</el-button>
      <el-button class="btn-gray" @click="toList" :disabled="isSubmit">{{$t('btnI18n.Cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import filter from "@/filters";

export default {
  name: "assets-create",
  data() {
    let reg2Decimal = (rule, value, callback) => {
      const reg = /^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/;
      // const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if (!value || value === "") {
        callback(new Error(this.$t('completeInput')));
      } else {
        callback(new Error(this.$t('formValidate.AmountError')));
      }
    };
    let reg2DecimalNull = (rule, value, callback) => {
      const reg = /^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/;
      if (!value || value === "") {
        callback();
      } else {
        callback(new Error(this.$t('formValidate.AmountError')));
      }
    };
    return {
      headers: {},
      businessType: this.$enums.BUSINESS_TYPE,
      repayType: this.$enums.REPAY_TYPE,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 60 * 60 * 1000;//如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      requiredInvoiceUrl: false,
      requiredDetail: false,
      // requiredTicketUrl: false,
      formFormat: {
        amount: "",
        invoiceValue: "",
        contractAmount: "",
      },
      formInfo: {
        businessType: "",
        contractAmount: "",
        contractName: "",
        contractNumber: "",
        contractValid: "",
        payer: "",
        invoiceValue: "",
        invoiceCode: "",
        invoice: "",
        amount: "",
        dueDate: "",
        desctiption: "",
        contractPaymentMode: "",
        contractDetail: ''
        // ticketUrl: []
      },
      rules: {
        businessType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        payer: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        amount: [{ required: true, trigger: 'blur' }],
        dueDate: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        desctiption: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoice: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoiceCode: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoiceValue: [{ required: true, trigger: 'blur' }],
        contractNumber: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractName: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractAmount: [{ required: false, trigger: 'blur' }],
        contractValid: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractPaymentMode: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        contractDetail: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
      },
      brandOwnerList: [],
      isSubmit: false,
      mySelfArr: [
        {
          boUser: {
            id: "1538728455825477631",
            username: this.$store.getters['auth/user'].username
          }
        }
      ]
    };
  },
  created() {
    this.getBrandOwnerList();
  },
  mounted() {
    this.headers = { token: this.$store.getters['auth/user'].token };
  },
  methods: {
    formatAmount() {
      this.formFormat.amount = this.formFormat.amount.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.amount) && this.formFormat.amount > 0) {
        this.formFormat.amount = filter.numberFormat(this.formFormat.amount, 2);
        this.formInfo.amount = filter.reNumber(this.formFormat.amount);
      } else {
        this.formFormat.amount = 1;
        this.formInfo.amount = 1;
      }
    },
    formatAmount2() {
      this.formFormat.invoiceValue = this.formFormat.invoiceValue.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.invoiceValue) && this.formFormat.invoiceValue > 0) {
        this.formFormat.invoiceValue = filter.numberFormat(this.formFormat.invoiceValue, 2);
        this.formInfo.invoiceValue = filter.reNumber(this.formFormat.invoiceValue);
      } else {
        this.formFormat.invoiceValue = 1;
        this.formInfo.invoiceValue = 1;
      }
    },
    formatAmount3() {
      this.formFormat.contractAmount = this.formFormat.contractAmount.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.contractAmount) && this.formFormat.contractAmount > 0) {
        this.formFormat.contractAmount = filter.numberFormat(this.formFormat.contractAmount, 2);
        this.formInfo.contractAmount = filter.reNumber(this.formFormat.contractAmount);
      } else {
        this.formFormat.contractAmount = 1;
        this.formInfo.contractAmount = 1;
      }
    },
    pickerDateFocus() {
      if (!this.formInfo.dueDate) {
        this.$set(this.formInfo, "dueDate", new Date(new Date().getTime() + 60 * 60 * 1000));
      }
    },
    getBrandOwnerList() {
      const data = {
        page: 1,
        pageSize: 10000
      };
      const mySelf = this.$store.getters['auth/user'].username;
      this.$axios.get("/v1/supplier/brandowners", { params: data }).then(result => {
        if (result.code === 0 || result.code === '0') {
          this.brandOwnerList = (result.data.rows).concat(this.mySelfArr);
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => console.log(error));
    },
    submitClick() {
      this.isSubmit = true;
      let submit = true;
      if (!this.formInfo.invoice || !this.formInfo.contractDetail) {
        submit = false;
        this.requiredInvoiceUrl = !this.formInfo.invoice;
        this.requiredDetail = !this.formInfo.contractDetail;
        this.isSubmit = false;
        return this.$message.error(this.$t('plaseUpload'));
      }
      if ((this.formInfo.contractAmount || this.formInfo.contractName || this.formInfo.contractNumber || this.formInfo.contractValid)
        && (!(this.formInfo.contractAmount && this.formInfo.contractName && this.formInfo.contractNumber && this.formInfo.contractValid))) {
        submit = false;
        this.isSubmit = false;
        return this.$message.error(this.$t('completeInput'));
      }
      if (!this.formInfo.contractAmount) {
        delete this.formInfo.contractAmount;
      }
      if (!this.formInfo.contractName) {
        delete this.formInfo.contractName;
      }
      if (!this.formInfo.contractNumber) {
        delete this.formInfo.contractNumber;
      }
      if (!this.formInfo.contractValid) {
        delete this.formInfo.contractValid;
      }
      if (this.formInfo.payer === this.mySelfArr[0].boUser.id) {
        delete this.formInfo.payer;
      }
      submit && this.$refs.formInfo.validate((valid) => {
        if (!valid) {
          submit = false;
          this.isSubmit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      if (submit) {
        let info = this.formInfo;
        info.amount = ((info.amount) * this.$enums.UNIT_MILLION).toString();
        info.invoiceValue = ((info.invoiceValue) * this.$enums.UNIT_MILLION).toString();
        if (info.contractAmount) {
          info.contractAmount = ((info.contractAmount) * this.$enums.UNIT_MILLION).toString();
        }
        this.$axios.post("/v1/supplier/receivable-order", info).then(result => {
          if (result.code === 0 || result.code === '0') {
            this.isSubmit = false;
            this.$router.push({ path: "/assets-list" });
          } else {
            this.isSubmit = false;
            this.$message({ type: 'error', message: result.message });
          }
        }).catch((error) => {
          console.log("submit form error", error);
          this.isSubmit = false;
        });
      }
    },
    toList() {
      this.$router.push({ path: "/assets-list" });
    },

    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.warning(this.$t("tipsMessage.UploadImgError"));
      }
      return isLt10M;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: this.$t('companyCreate.limitedSelected10'),
        type: 'warning',
      });
    },
    handleRemove1(file, fileList) {
      let dataArr = [];
      fileList.map((file) => {
        if (file.response) {
          dataArr.push(file.response.data);
        }
      });
      this.formInfo.contractDetail = JSON.stringify(dataArr);
    },
    onSuccess1(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
        this.formInfo.contractDetail = JSON.stringify(dataArr);
      }
    },
    handleRemove2(file, fileList) {
      let dataArr = [];
      fileList.map((file) => {
        if (file.response) {
          dataArr.push(file.response.data);
        }
      });
      this.formInfo.invoice = JSON.stringify(dataArr);
    },
    onSuccess2(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
        this.formInfo.invoice = JSON.stringify(dataArr);
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style scoped lang="scss">
.el-form-item {
  width: 550px;
  display: inline-block;
}
.check-example {
  margin-left: 15px;
}
</style>
